import { Avatar, Card, CardContent, Grid, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { formattedMoney } from "../../ultils";
export const TotalProfit = (props) => (
    <Card {...props} sx={{ backgroundColor: "#e5e5e5", height: "100%" }}>
        <CardContent>
            <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
                <Grid item>
                    <Typography color="textSecondary" gutterBottom variant="overline">
                        Tổng cược
                    </Typography>
                    <Typography color="textPrimary" variant="h4" style={{ fontSize: "1.2rem" }}>
                        {formattedMoney(props.tongcuoc)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Avatar
                        sx={{
                            backgroundColor: "primary.main",
                            height: 56,
                            width: 56
                        }}>
                        <AttachMoneyIcon />
                    </Avatar>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

import "./footer.css";
import axios from "axios";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { formattedMoney } from '../../ultils'

function Footer({ profile }) {
    const data = [
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[20px]" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" d="m11.3 9.8l-2-2q-.15-.15-.225-.337T9 7.075V3q0-.425.288-.712T10 2h4q.425 0 .713.288T15 3v4.075q0 .2-.075.388T14.7 7.8l-2 2q-.15.15-.325.213t-.375.062t-.375-.062T11.3 9.8m2.9 2.9q-.15-.15-.212-.325T13.925 12t.063-.375t.212-.325l2-2q.15-.15.338-.225T16.925 9H21q.425 0 .713.288T22 10v4q0 .425-.288.713T21 15h-4.075q-.2 0-.387-.075T16.2 14.7zM2 14v-4q0-.425.288-.712T3 9h4.075q.2 0 .388.075T7.8 9.3l2 2q.15.15.213.325t.062.375t-.062.375t-.213.325l-2 2q-.15.15-.337.225T7.075 15H3q-.425 0-.712-.288T2 14m7 7v-4.075q0-.2.075-.387T9.3 16.2l2-2q.15-.15.325-.212t.375-.063t.375.063t.325.212l2 2q.15.15.225.338t.075.387V21q0 .425-.288.713T14 22h-4q-.425 0-.712-.288T9 21"></path>
                </svg>
            ),
            title: "Vòng Quay",
            to: "/"
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[20px]" width="1em" height="1em" viewBox="0 0 24 24">
                    <path fill="currentColor" fillRule="evenodd" d="M6.271 2.112c-.81.106-1.238.301-1.544.6c-.305.3-.504.72-.613 1.513C4.002 5.042 4 6.124 4 7.675v8.57a4.2 4.2 0 0 1 1.299-.593c.528-.139 1.144-.139 2.047-.138H20V7.676c0-1.552-.002-2.634-.114-3.451c-.109-.793-.308-1.213-.613-1.513c-.306-.299-.734-.494-1.544-.6c-.834-.11-1.938-.112-3.522-.112H9.793c-1.584 0-2.688.002-3.522.112m.488 4.483c0-.448.37-.811.827-.811h8.828a.82.82 0 0 1 .827.81a.82.82 0 0 1-.827.811H7.586a.82.82 0 0 1-.827-.81m.827 2.973a.82.82 0 0 0-.827.81c0 .448.37.811.827.811h5.517a.82.82 0 0 0 .828-.81a.82.82 0 0 0-.828-.811z" clipRule="evenodd"></path>
                    <path fill="currentColor" d="M7.473 17.135H20c-.003 1.13-.021 1.974-.113 2.64c-.109.793-.308 1.213-.613 1.513c-.306.299-.734.494-1.544.6c-.834.11-1.938.112-3.522.112H9.793c-1.584 0-2.688-.002-3.522-.111c-.81-.107-1.238-.302-1.544-.601c-.305-.3-.504-.72-.613-1.513c-.041-.3-.068-.637-.084-1.02a2.46 2.46 0 0 1 1.697-1.537c.29-.076.667-.083 1.746-.083"></path>
                </svg>
            ),
            title: "Giới Thiệu",
            to: "/about"
        },
        {
            icon: (
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[20px]" width="1em" height="1em" viewBox="0 0 24 24">
                    <g fill="none" stroke="currentColor" strokeWidth="1.5">
                        <circle cx="12" cy="6" r="4"></circle>
                        <path d="M20 17.5c0 2.485 0 4.5-8 4.5s-8-2.015-8-4.5S7.582 13 12 13s8 2.015 8 4.5Z"></path>
                    </g>
                </svg>
            ),
            title: "Cá Nhân",
            to: "/mine"
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            axios.post(`${process.env.REACT_APP_API_URL}/auth/updatestatus`, { online: true }).then((res) => { });
        }, 5000);
        return () => clearInterval(interval);
    }, [profile]);

    return (
        <div className="bg-menu">
            {profile && (
                <div className="detail_id">
                    <div className="item_id">
                        {/* Điểm Quay Hiện Có: <b>{profile.money}</b> */}
                        Điểm Quay Hiện Có: <b>{formattedMoney(profile.money)}</b>
                    </div>
                    <div className="item_id">ID : {Number(profile?.iduser)}</div>
                    {/* <LiveChat license={16484763} onChatLoaded={(ref) => setTimeout(() => { window.LC_API.hide_chat_window(); }, 3000)} /> */}
                </div>
            )}
            <div className="footer">
                {data.map((item, index) => (
                    <div className="item-footer" key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            <div className="icon_footer">{item.icon}</div>
                            <div className="title_footer">{item.title}</div>
                        </Link>
                    </div>
                ))}
                <div className="item-footer">
                    <div style={{ cursor: "pointer" }} className="livechat_button" onClick={() => {/* window.LC_API.open_chat_window() */ }}>
                        <Link style={{ textDecoration: "none" }} to="/cskh">
                            <div className="icon_footer">
                                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon text-[20px]" width="1em" height="1em" viewBox="0 0 24 24">
                                    <g fill="none">
                                        <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                                        <path fill="currentColor" d="M13.5 3a8.5 8.5 0 0 1 0 17H13v.99A1.01 1.01 0 0 1 11.989 22c-2.46-.002-4.952-.823-6.843-2.504C3.238 17.798 2.002 15.275 2 12.009V11.5A8.5 8.5 0 0 1 10.5 3zm0 2h-3A6.5 6.5 0 0 0 4 11.5l.001.665c.04 2.642 1.041 4.562 2.475 5.836C7.714 19.103 9.317 19.76 11 19.945v-.935c0-.558.452-1.01 1.01-1.01h1.49a6.5 6.5 0 1 0 0-13m-5 5a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3m7 0a1.5 1.5 0 1 1 0 3a1.5 1.5 0 0 1 0-3"></path>
                                    </g>
                                </svg>
                            </div>
                            <div className="title_footer">CSKH</div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;

import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import "./addmn.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function AddBank() {
	const [profile, setProfile] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	const [bank, setBank] = useState(null);
	const navigate = useNavigate();
	useEffect(() => {
		axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
		axios
			.get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
			.then((res) => {
				setBank(res.data.data);
			})
			.catch((err) => setBank(null));
	}, []);
	const onSubmit = (data) => {
		const formData = {
			name_bank: data.name_bank,
			stk: data.stk,
			fullname: data.fullname,
			user: profile._id,
		};
		axios
			.post(`${process.env.REACT_APP_API_URL}/bank/create`, formData)
			.then((res) => {
				swal({
					title: "Thành công",
					text: "Liên kết ngân hàng thành công",
					icon: "success",
					buttons: "Đồng ý",
				}).then(() => navigate("/mine"));
			})
			.catch((err) =>
				setError("money", {
					type: "minLength",
					message: "Lỗi giao dịch 404!",
				})
			);
	};
	return (
        <>
            <div className="app1">
                <div className="info_profile nav-back shadow">
                    <div className="cycle_bet">
                        <svg onClick={() => window.history.back()} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.3rem" height="1.3rem" viewBox="0 0 24 24" fill="#465a7a">
                            <path fill="#465a7a" d="m7.825 13l4.9 4.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-6.6-6.6q-.15-.15-.213-.325T4.426 12t.063-.375t.212-.325l6.6-6.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L7.825 11H19q.425 0 .713.288T20 12t-.288.713T19 13z"></path>
                        </svg>

                        <span className="info_bet">Liên kết ngân hàng</span>
                    </div>
                </div>
                <div className="content_profile">
                    <div className="list-bank">
                        {bank != null ? (
                            <>
                                <form
                                    style={{
                                        display: "block",
                                        padding: "15px",
                                        textAlign: "left"
                                    }}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div>
                                            <label>Tên ngân hàng</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value="*********"
                                                readonly=""
                                                placeholder="Nhập tên ngân hàng"
                                                {...register("name_bank", { required: true })}
                                            />
                                        </div>
                                        <div>
                                            {" "}
                                            <label>Số tài khoản</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value="*********"
                                                readonly=""
                                                placeholder="Nhập số tài khoản"
                                                {...register("stk", { required: true })}
                                            />
                                        </div>
                                        <div>
                                            {" "}
                                            <label>Chủ tài khoản</label>
                                            <input
                                                className="ipadd"
                                                type="text"
                                                value="*********"
                                                readonly=""
                                                placeholder="Nhập họ tên người nhận"
                                                {...register("fullname", { required: true })}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </>
                        ) : (
                            <>
                                <form
                                    style={{
                                        display: "block",
                                        padding: "15px",
                                        textAlign: "left"
                                    }}
                                    onSubmit={handleSubmit(onSubmit)}>
                                    <div>
                                        <div>
                                            <label>Tên ngân hàng</label>
                                            <input className="ipadd" type="text" {...register("name_bank", { required: true })} placeholder="Nhập tên ngân hàng" />
                                        </div>
                                        <div>
                                            {" "}
                                            <label>Số tài khoản</label>
                                            <input className="ipadd" type="number" {...register("stk", { required: true })} placeholder="Nhập số tài khoản" />
                                        </div>
                                        <div>
                                            {" "}
                                            <label>Chủ tài khoản</label>
                                            <input className="ipadd" type="text" {...register("fullname", { required: true })} placeholder="Nhập họ tên người nhận" />
                                        </div>
                                        <button type="submit" className="btn-submit">
                                            Xác nhận
                                        </button>
                                    </div>
                                </form>
                            </>
                        )}
                    </div>
                </div>

                <Footer />
            </div>
        </>
    );
}
export default AddBank;

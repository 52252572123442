export const formattedMoney = (money) => {
    // Kiểm tra nếu số tiền nhỏ hơn 1000 thì giữ nguyên
    const roundedMoney = money < 1000 ? money : Math.round(money / 1000) * 1000;

    // Định dạng theo tiêu chuẩn Việt Nam
    return roundedMoney.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND",
        maximumFractionDigits: 0 // Không giữ phần thập phân
    });
};

import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function WithDraw() {
    const [profile, setProfile] = useState(null);
    const [bank, setBank] = useState(null);
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
        axios
            .get(`${process.env.REACT_APP_API_URL}/bank/getBank`, {})
            .then((res) => {
                setBank(res.data.data);
            })
            .catch((err) => setBank(true));
    }, []);
    const onSubmit = (data) => {
        if (data.money > profile.money) {
            setError("money", {
                type: "minLength",
                message: "Số điểm rút vui lòng nhỏ hơn Số điểm hiện có"
            });
            return;
        }
        if (data.money < 50) {
            setError("money", {
                type: "minLength",
                message: "Số điểm rút tối thiểu là 50"
            });
            return;
        }
        if (!bank || bank.length === 0) {
            swal({
                title: "Bạn chưa liên kết ngân hàng",
                text: "Vui lòng liên kết thẻ ngân hàng để rút điểm",
                icon: "info",
                buttons: "Liên kết ngay"
            }).then(() => navigate("/addbank"));
            return; // Return to prevent further execution
        }

        // Only access bank[0] if bank is not empty
        const bankDetails = bank[0];
        const formData = {
            money: data.money,
            type_payment: "RÚT",
            detail: bankDetails ? `${bankDetails.fullname} - ${bankDetails.stk} - ${bankDetails.name_bank}` : "Chưa có ngân hàng",
            status_payment: "Pending",
            user: profile._id
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/payment/withDraw`, formData)
            .then((res) => {
                swal({
                    title: "Thành công",
                    text: "",
                    icon: "success",
                    buttons: "OK"
                }).then(() => navigate("/mine"));
            })
            .catch((err) =>
                setError("money", {
                    type: "minLength",
                    message: "Lỗi giao dịch 404!"
                })
            );
    };

    return (
        <>
            <div className="app1">

                <div className="info_profile nav-back shadow">
                    <div className="cycle_bet">
                        <svg  onClick={() => window.history.back()} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.3rem" height="1.3rem" viewBox="0 0 24 24" fill="#465a7a">
                            <path fill="#465a7a" d="m7.825 13l4.9 4.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-6.6-6.6q-.15-.15-.213-.325T4.426 12t.063-.375t.212-.325l6.6-6.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L7.825 11H19q.425 0 .713.288T20 12t-.288.713T19 13z"></path>
                        </svg>

                        <span className="info_bet">Rút tiền</span>
                    </div>
                </div>
                <div className="content_profile">
                    <form style={{ display: "block", padding: "15px", textAlign: "left" }} onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            {bank == null && !profile ? (
                                <>
                                </>
                            ) : (
                                    <>
                                    <div>Số tiền</div>
                                    <div>
                                        <input className="ipadd" type="number" {...register("money", { required: true })} placeholder="Nhập số tiền cần rút" />
                                    </div>
                                    <button type="submit" className="btn-submit btn-widthdraw">
                                        Xác Nhận  Rút
                                    </button>
                                </>
                            )}

                            {errors.money ? <p style={{ color: "red" }}>{errors.money.message}</p> : null}
                        </div>
                    </form>
                </div>

                <Footer profile={profile} />
            </div>
        </>
    );
}
export default WithDraw;

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import "./login.css";

function Login() {
    const [err, setErr] = useState(null);
    const login = localStorage.getItem("user");
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors }
    } = useForm();
    const navigate = useNavigate();
    useEffect(() => {
        if (login) {
            navigate("/");
        }
    }, [login, navigate]);
    const onSubmit = async (data) => {
        if (data.username !== "administrator") {
            if (data.username.length < 6) {
                setError("username", {
                    type: "minLength",
                    message: "Tên đăng nhập không hợp lệ"
                });
                return;
            }
        }
        if (data.password.length < 6) {
            setError("password", {
                type: "minLength",
                message: "Mật khẩu không hợp lệ"
            });
            return;
        }
        axios
            .post(`${process.env.REACT_APP_API_URL}/auth/login`, data)
            .then((res) => {
                localStorage.setItem("user", res.data.data);
                swal({
                    title: "Thông báo",
                    text: "Đăng nhập thành công",
                    icon: "success",
                    buttons: "OK"
                }).then(() => {
                    navigate("/");
                });
            })
            .catch((err) => setErr("Tên đăng nhập hoặc mật khẩu không chính xác"));
    };
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <div className="auth-page">
            <div className="app123">
                <form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
                    <h3>KẾT NỐI YÊU THƯƠNG</h3>
                    <div className="action-auth">
                        <button className="btn-lg active" type="button" onClick={() => navigate("/login")}>
                            Đăng Nhập
                        </button>
                        <button className="btn-lg" type="button" onClick={() => navigate("/register")}>
                            Đăng Ký
                        </button>
                    </div>
                    <div className="inputs">
                        <div>
                            <label htmlFor="username">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="icon"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="currentColor" d="M18.39 14.56C16.71 13.7 14.53 13 12 13s-4.71.7-6.39 1.56A2.97 2.97 0 0 0 4 17.22V20h16v-2.78c0-1.12-.61-2.15-1.61-2.66M9.78 12h4.44c1.21 0 2.14-1.06 1.98-2.26l-.32-2.45C15.57 5.39 13.92 4 12 4S8.43 5.39 8.12 7.29L7.8 9.74c-.16 1.2.77 2.26 1.98 2.26"></path>
                                </svg>
                            </label>
                            <input
                                type="text"
                                className="ip-lg"
                                {...register("username", { required: true })}
                                placeholder="Tài khoản"
                            />
                            {errors.username && <p>{errors.username.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="password">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="icon"
                                    width="1em"
                                    height="1em"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="currentColor" d="M12.65 10a6 6 0 0 0-6.88-3.88c-2.29.46-4.15 2.29-4.63 4.58A6.006 6.006 0 0 0 7 18a5.99 5.99 0 0 0 5.65-4H17v2c0 1.1.9 2 2 2s2-.9 2-2v-2c1.1 0 2-.9 2-2s-.9-2-2-2zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2"></path>
                                </svg>
                            </label>
                            <input
                                type={showPassword ? "text" : "password"}
                                className="ip-lg"
                                {...register("password", { required: true })}
                                placeholder="Mật khẩu"
                            />
                            <div onClick={toggleShowPassword} className="change-visible">
                                {showPassword ? <VisibilityOff sx={{ color: "#333" }} /> : <Visibility sx={{ color: "#333" }} />}
                            </div>
                            {errors.password && <p>{errors.password.message}</p>}
                        </div>
                    </div>
                    {err ? <p style={{ color: "red" }}>{err}</p> : null}
                    <p className="p-lg">
                        <Link className="a-lg" to="https://direct.lc.chat/16952547">
                            Quên mật khẩu?
                        </Link>
                    </p>
                    <button className="btn-lg" type="submit">
                        Tiếp Tục
                    </button>
                    <button className="clear-btn "onClick={() => navigate("/register")}>
                        Bạn chưa có tài khoản? <span>Đăng ký ngay</span>
                    </button>
                </form>
            </div>
        </div>
    );
}
export default Login;

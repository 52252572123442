import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

import { formattedMoney } from '../../ultils'

import pending from "../../img/pending.png";
import success from "../../img/checked.png";
import error from "../../img/close.png";

import logo from "../../img/1.png";
function History() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [profile, setProfile] = useState(null);
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");
			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_API_URL}/payment/paymentus`, {})
			.then((res) => {
				setData(res.data.data);
			})
			.catch((err) => function () {});
		axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
	}, []);
	return (
		<>
			<div className="app1">
				<div className="info_profile nav-back shadow">
					<div className="cycle_bet">
						<svg onClick={() => window.history.back()} xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.3rem" height="1.3rem" viewBox="0 0 24 24" fill="#465a7a">
							<path fill="#465a7a" d="m7.825 13l4.9 4.9q.3.3.288.7t-.313.7q-.3.275-.7.288t-.7-.288l-6.6-6.6q-.15-.15-.213-.325T4.426 12t.063-.375t.212-.325l6.6-6.6q.275-.275.688-.275t.712.275q.3.3.3.713t-.3.712L7.825 11H19q.425 0 .713.288T20 12t-.288.713T19 13z"></path>
						</svg>

						<span className="info_bet">Lịch sử giao dịch</span>
					</div>
				</div>
				{data ? (
					<div className="content_profile" style={{ padding: "0 0 95px" }}>
						{data?.map((item, key) => (
							<>
								{item.type_payment === "NẠP" ? (
									<>
										<div key={key} className="history_payment">
											<div style={{ display: "flex" }}>
												{item.status_payment === "Pending" ? (
													<img
														src={pending}
														style={{
															width: "35px",
															height: "35px",
															transform: "scale(1.05)",
															backgroundColor: "white",
														}}
													/>
												) : null}
												{item.status_payment === "Success" ? (
													<img
														src={success}
														style={{
															width: "35px",
															height: "35px",
															backgroundColor: "white",
														}}
													/>
												) : null}
												{item.status_payment === "Deny" ? (
													<img
														src={error}
														style={{
															width: "35px",
															height: "35px",
															backgroundColor: "white",
														}}
													/>
												) : null}
												<div className="type_payment">
													<div className="typepayment_detail">
														{item.type_payment}
													</div>
													<div className="date_time">
														{formatDate(new Date(item.createdAt))}
													</div>
													{item.status_payment === "Success" ? (
														<div
															style={{ color: "green" }}
															className="typepayment_detail"
														>
															Thành công
														</div>
													) : null}
													{item.status_payment === "Deny" ? (
														<div
															style={{ color: "red" }}
															className="typepayment_detail"
														>
															Thất bại
														</div>
													) : null}
													{item.status_payment === "Pending" ? (
														<div
															style={{ color: "orange" }}
															className="typepayment_detail"
														>
															Đang xử lý
														</div>
													) : null}
												</div>
											</div>
											{item.type_payment === "RÚT" ? null : (
												<div style={{ color: "green" }} className="money_pamn">
													+{formattedMoney(item.money)}
												</div>
											)}
											{item.type_payment === "NẠP" ? null : (
												<div style={{ color: "red" }} className="money_pamn">
													-{formattedMoney(item.money)}
												</div>
											)}
										</div>
										<hr className="line-pay" />
									</>
								) : null}
							</>
						))}
					</div>
				) : (
					<></>
				)}

				<Footer profile={profile}/>
			</div>
		</>
	);
}
export default History;

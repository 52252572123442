import { Outlet, Navigate } from "react-router";
import { LoginStatus } from "./hook/checkLg";
import axios from "axios";
import { useEffect, useState } from "react";
const PrivateRouteAdmin = () => {
	const [checking, setChecking] = useState(true);
	const [isAdmin, setIsAdmin] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("admin");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		const token = localStorage.getItem("admin");
		if (token) {
			axios
				.get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
				.then((res) => {
					setChecking(false);
					setIsAdmin(res.data.data.isAdmin);
				})
				.catch((res) => {
					setChecking(false);
				});
		}
	}, []);

	if (checking) {
		return (
			<>
				<div></div>
			</>
		);
	}
	return isAdmin ? <Outlet /> : <Navigate to="/admincp" />;
};
export default PrivateRouteAdmin;

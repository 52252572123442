import axios from "axios";
import { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import { Slide } from 'react-slideshow-image';
import InfoIcon from "@mui/icons-material/Info";

import Img1 from '../../img/custom/title.jpg'
import Img2 from '../../img/custom/QuyTrinh-5.png'

function Game1() {
    const [isVisible, setVisible] = useState(null);
    const [bet, setBet] = useState(null);
    const [profile, setProfile] = useState(null);
    const [second, setSecond] = useState(0);
    const [minute, setMinute] = useState(1);
    const [start, setStart] = useState(false);
    const [dulieunhap, setDulieunhap] = useState(new Date());
    const [update, setUpdate] = useState(0);
    const [err, setErr] = useState(null);
    const [active, setActive] = useState(null);
    const date = new Date();
    const currentMinute = date.getMinutes();
    const currentSecond = date.getSeconds();
    const [state, setState] = useState(null);
    const [total, setTotal] = useState(null);
    const [isShow, setShow] = useState(false);
    const [showConfetti, setShowConfetti] = useState(false);
    const [choose, setChoose] = useState(null);
    const [quantity, setQuantity] = useState(100);
    const [tabActive, setTabActive] = useState(1);

    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const {
        watch,
        register,
        handleSubmit,
        setError,
        getValues,
        formState: { errors }
    } = useForm();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    useEffect(() => {
        if (start === false) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
                .then((res) => {
                    setProfile(res.data.data);
                })
                .catch(() => {
                    localStorage.removeItem("user");
                    navigate("/login");
                });
            axios.get(`${process.env.REACT_APP_API_URL}/bet1/get`).then((res) => {
                setBet(res.data.data);
                setDulieunhap(new Date(res.data.data.createdAt));
                setStart(true);
            });
            axios
                .get(`${process.env.REACT_APP_API_URL}/bet1/getallbet`, {})
                .then((res) => {
                    setTotal(res.data.data);
                })
                .catch(() => setTotal(null));
        }
    }, [start]);
    useEffect(() => {
        const interval = setInterval(() => {
            axios.get(`${process.env.REACT_APP_API_URL}/notification/getnotifi`, {}).then((res) => {
                if (res?.data.data[0]) {
                    setVisible({
                        money: res?.data?.data[0]?.money.toLocaleString(),
                        id: res?.data?.data[0]?._id,
                        msg: res?.data?.data[0]?.msg
                    });
                }
            });
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        let swalInst;
        const showAlert = async (data) => {
            setShowConfetti(true);
            swalInst = swal({
                title: "Xin chúc mừng !!!",
                text: `Chúc mừng quý khách mang ID ${Number(profile?.iduser)
                    } đã trúng giải thưởng ngẫu nhiên trị giá ${data.money.toLocaleString()} VNĐ. Vui lòng liên hệ CSKH để biết thêm chi tiết.`,
                //text: data.msg,
                className: "bg-gift",
                //icon: GiftImg,
                buttons: {
                    submit: "Đồng ý"
                }
            }).then(() => {
                setShowConfetti(false);
                axios.post(`${process.env.REACT_APP_API_URL}/notification/seen`, {
                    id: data.id
                });
            });
            setVisible(false);
        };
        if (isVisible) {
            showAlert(isVisible);
        }
    }, [isVisible]);
    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);

        let myTimeout;
        // if (curTime_second <= 0) {
        // 	axios.get(`${process.env.REACT_APP_API_URL}//bet/get`).then((res) => {
        // 		let date = new Date();
        // 		let date1 = new Date(res.data.data[0].createdAt);
        // 		if (date - date1 > 60000) {
        // 			setBet(null);
        // 		} else {
        // 			setBet(res.data.data[0]);
        // 			setDulieunhap(new Date(res.data.data[0].createdAt));
        // 		}
        // 	});
        // }

        if (currentMinute === dulieunhap.getMinutes() && currentSecond === dulieunhap.getSeconds()) {
            setStart(true);
            setSecond(second - 1);
            return () => {
                clearTimeout(myTimeout);
            };
        } else if (curTime_second < 60 && curTime_second >= 0) {
            setSecond(curTime_second % 60);
            setMinute((curTime_second - (curTime_second % 60)) / 60);
            setStart(true);
            return () => {
                clearTimeout(myTimeout);
            };
        } else {
            //cập nhật thời gian hiện tại 0.5s/lần
            myTimeout = setTimeout(() => {
                setUpdate(update + 1);
            }, 500);
        }
    }, [update, dulieunhap]);

    useEffect(() => {
        let curTime_second = Math.floor(60 - (date - dulieunhap) / 1000);
        let myTimeout = 0;
        if (start) {
            setSecond(curTime_second % 60);
            setMinute(Math.floor(curTime_second / 60));

            if (curTime_second > 60 || curTime_second <= 0) {
                setStart(false);
                setMinute(1);
                setSecond(0);
                return () => {
                    clearTimeout(myTimeout);
                };
            }
            myTimeout = setTimeout(() => {
                setSecond(second - 1);
            }, 1000);
        }
        return () => {
            clearTimeout(myTimeout);
        };
    }, [second, start, dulieunhap]);

    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const onChoose = (e) => {
        setChoose(e);
        setActive(e);
        setShowPopup(true);
    };
    const onSubmit = async (data) => {
        if (quantity == 0) {
            swal("Thông báo", "Vui lòng nhập số tiền", "info");
            return;
        }
        if (!choose) {
            swal("Thông báo", "Vui lòng chọn ô", "warning");
            return;
        }
        const formDate = {
            id: bet._id,
            money: quantity,
            bet: choose
        };

        axios
            .post(`${process.env.REACT_APP_API_URL}/history1/choose`, formDate)
            .then((res) => {
                swal("Thành công", "Nhập thành công", "success");
                setStart(false);
                setErr(res.data.message);
                setShowPopup(false);
            })
            .catch((res) => swal("error", res.response.data.message, "error"));

        resetForm();
    };

    const handleClose = () => {
        setShowPopup(false);
    };

    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };

    const handleSetValue = (value) => {
        setQuantity(value);
    };

    const resetForm = () => {
        setQuantity(100);
        setActive(null);
        setState(null);
        setShowPopup(false);
    };

    return (
        <>
            <div className="app1" style={{ paddingBottom: "60px" }}>
                <div style={{ background: "#f43f5e", padding: "4px 0" }}>
                    <div data-v-69b3b4a1="" data-v-0f9d40fc="" className="lottery-header theme-main-bg theme-header-bottom" data-v-435dc89a="">
                        <section data-v-82095748="" data-v-0f9d40fc="" className="lottery-timer theme-main-bg-5 theme-after-border-color-1" data-v-69b3b4a1="">

                            <div data-v-82095748="" className="content-box">
                                <div>
                                    Kỳ hiện tại
                                    <span> {total ? total[0]?.id_bet : "----"}</span>
                                </div>
                            </div>
                        </section>

                    </div>
                </div>
                <div className="record_bet">
                    <div data-v-4a0db828="" className="lottery-result">
                        <div className="bet__title">
                            <div className="time">
                                <div className="box">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        role="img"
                                        className="icon"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                        aria-label="Clock Icon"
                                    >
                                        <path fill="currentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8m-.22-13h-.06c-.4 0-.72.32-.72.72v4.72c0 .35.18.68.49.86l4.15 2.49c.34.2.78.1.98-.24a.71.71 0 0 0-.25-.99l-3.87-2.3V7.72c0-.4-.32-.72-.72-.72"></path>
                                    </svg>
                                    <div className="content theme-color-white">
                                        0{minute} : {second < 10 ? "0" : ""}
                                        {second}
                                    </div>
                                </div>
                                <div className="info-icon" onClick={openPopup}>
                                    <InfoIcon />
                                </div>
                            </div>
                        </div>
                        {/* <div data-v-4a0db828="" className="content">
                            {total != null && (
                                <ul data-v-4a0db828="" className="clear-fix list">
                                    <li data-v-4a0db828="" className="num-item ball   i0 number1 numberlist">
                                        {total[0].result.split(" ")[0]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i1 number2 numberlist">
                                        {total[0].result.split(" ")[1]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i2 number3 numberlist">
                                        {total[0].result.split(" ")[2]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i3 number4 numberlist">
                                        {total[0].result.split(" ")[3]}
                                    </li>
                                    <li data-v-4a0db828="" className="num-item ball  i4 number5 numberlist">
                                        {total[0].result.split(" ")[4]}
                                    </li>
                                </ul>
                            )}
                        </div> */}
                    </div>

                    <div className="bet__title">
                        <h2 className="uppercase">VÒNG QUAY TÌNH YÊU</h2>
                    </div>

                    <div className="tab1">
                        <ul data-v-0f9d40fc="" className="lottery-code-panel-body">
                            <li data-v-0f9d40fc="" className="ybcp-num-item">
                                <span
                                    data-v-0f9d40fc=""
                                    className={`lottery-code-item-name ${active == 1 && "active"}`}
                                    onClick={(e) => {
                                        onChoose(1);
                                    }}>
                                    TĂNG
                                </span>
                            </li>
                            <li data-v-0f9d40fc="" className="ybcp-num-item">
                                <span
                                    data-v-0f9d40fc=""
                                    className={`lottery-code-item-name ${active == 2 && "active"}`}
                                    onClick={(e) => {
                                        onChoose(2);
                                    }}>
                                    GIẢM
                                </span>
                            </li>
                            {/* <li data-v-0f9d40fc="" className="ybcp-num-item">
                                <span
                                    data-v-0f9d40fc=""
                                    className={`lottery-code-item-name ${active == 3 && "active"}`}
                                    onClick={(e) => {
                                        onChoose(3);
                                    }}>
                                    TRÊN
                                </span>
                            </li>
                            <li data-v-0f9d40fc="" className="ybcp-num-item">
                                <span
                                    data-v-0f9d40fc=""
                                    className={`lottery-code-item-name ${active == 4 && "active"}`}
                                    onClick={(e) => {
                                        onChoose(4);
                                    }}>
                                    DƯỚI
                                </span>
                            </li> */}
                        </ul>
                        <div className="game-box-img">
                            <img src={Img1} alt="" />
                            <img src={Img2} alt="" />
                        </div>

                    </div>

                </div>
            </div>

            {showConfetti && (
                <Confetti
                    style={{
                        width: "100vw",
                        height: "100vh",
                        maxWidth: "540px",
                        zIndex: "999999"
                    }}
                />
            )}
            {showPopup && (
                <>
                    <form className="lottery-bet-input" onSubmit={handleSubmit(onSubmit)}>

                        <div className="bet__title">
                            SỐ ĐIỂM QUAY
                        </div>
                        <div className="box">
                            <div className="bet-input">
                                <input {...register("money", { required: true })} type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} name="money" />
                            </div>
                            <div className="chip-row">
                                <div className={`chip ${quantity == 20 ? "active" : ""}`} onClick={() => handleSetValue(20)}>
                                    20
                                </div>
                                <div className={`chip ${quantity == 30 ? "active" : ""}`} onClick={() => handleSetValue(30)}>
                                    30
                                </div>
                                <div className={`chip ${quantity == 50 ? "active" : ""}`} onClick={() => handleSetValue(50)}>
                                    50
                                </div>
                                <div className={`chip ${quantity == 100 ? "active" : ""}`} onClick={() => handleSetValue(100)}>
                                    100
                                </div>
                                <div className={`chip ${quantity == 500 ? "active" : ""}`} onClick={() => handleSetValue(500)}>
                                    500
                                </div>
                                <div className={`chip ${quantity == 1000 ? "active" : ""}`} onClick={() => handleSetValue(1000)}>
                                    1,000
                                </div>
                                <div className={`chip ${quantity == 50000 ? "active" : ""}`} onClick={() => handleSetValue(50000)}>
                                    50,000
                                </div>
                                <div className={`chip ${quantity == 100000 ? "active" : ""}`} onClick={() => handleSetValue(100000)}>
                                    100,000
                                </div>
                                <div className={`chip ${quantity == 200000 ? "active" : ""}`} onClick={() => handleSetValue(200000)}>
                                    200,000
                                </div>

                            </div>
                            <div className="btn-action">
                                <button type="button" className="btn close" onClick={resetForm}>Huỷ</button>
                                <button type="submit" className="btn submit">Xác nhận quay</button>
                            </div>
                        </div>
                    </form>
                </>
            )
            }
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-box">
                        <div className="popup-hd-header">
                            <h3>THÔNG BÁO HỆ THỐNG</h3>
                        </div>
                        <div className="popup-hd-content">
                            <p>
                                ADDM inputdata nghiêm cấm mọi hình thức nhập gian lận, nếu bị phát hiện, web có quyền hạn thu hồi tất cả số tiền trên Web. Nếu có bất kì ý kiến hoặc kiến nghị nào, Vui lòng Lựa chọn "CSKH" ở dưới menu và để lại lời nhắn.
                            </p>
                            <br />
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được phần quà ngẫu nhiên may mắn từ Web, khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:
                            </p>
                            <br />
                            <ul>
                                <li>Hạn mức rút tài khoản dưới 200 triệu tương ứng 15% phí</li>
                                <li>Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí</li>
                                <li>Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí</li>
                            </ul>
                        </div>

                        <div className="popup-hd-close" onClick={closePopup}>
                            TÔI ĐÃ HIỂU
                        </div>
                    </div>
                </div>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Game1;

import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import Img from '../../img/custom/home-bg-1.jpg'
import Img2 from '../../img/custom/signed.png'

function About() {
    return (
        <>
            <div className="app1 about">
                <div className="info_profile">
                    <div className="cycle_bet">
                        <span className="info_bet">KẾT NỐI YÊU THƯƠNG</span>
                    </div>
                </div>
                <div className="box-head justify-between">
                    <div className="left">
                        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon" width="1em" height="1em" viewBox="0 0 24 24">
                            <g fill="none" stroke="currentColor" strokeWidth="1.5">
                                <path d="M4 8c0-2.828 0-4.243.879-5.121C5.757 2 7.172 2 10 2h4c2.828 0 4.243 0 5.121.879C20 3.757 20 5.172 20 8v8c0 2.828 0 4.243-.879 5.121C18.243 22 16.828 22 14 22h-4c-2.828 0-4.243 0-5.121-.879C4 20.243 4 18.828 4 16z"></path>
                                <path d="M19.898 16h-12c-.93 0-1.395 0-1.777.102A3 3 0 0 0 4 18.224"></path>
                                <path strokeLinecap="round" d="M8 7h8m-8 3.5h5m6.5 8.5H8"></path>
                            </g>
                        </svg>
                        <p className="title">Giới thiệu</p>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon" width="1em" height="1em" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M8.12 9.29L12 13.17l3.88-3.88a.996.996 0 1 1 1.41 1.41l-4.59 4.59a.996.996 0 0 1-1.41 0L6.7 10.7a.996.996 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0"></path>
                    </svg>
                </div>

                <div className="box-content">
                    <div className="content"><img src={Img} alt="" /> ❣️ Bạn không muốn một mối tình tạm bợ ? <br /> ❣️ Bạn lười bắt đầu một mối quan hệ mới với những câu làm quen nhàm chán ? <br /> ❣️ Bạn lười chữa lành những vết thương sau những lần đổ vỡ <br /> ❣️ Bạn lười giải thích, càng chẳng biết phải bày tỏ những cảm xúc trong lòng mình với người mới ra sao. <br /> ❣️ Bạn lười mở lòng và không muốn tin vào ai nữa. <br /> ➡️ Chúng tôi hiểu rằng việc tìm kiếm một người phù hợp không phải là điều dễ dàng, đặc biệt khi bạn đã trải qua những trăn trở trong quá khứ. <br /> ‼️ 𝗧𝗮̂𝗺 𝘀𝘂̛̣ 𝗰𝘂̀𝗻𝗴 𝗰𝗵𝘂́𝗻𝗴 𝘁𝗼̂𝗶 <br /> ‼️ 𝐵𝑎̉𝑜 𝑀𝑎̣̂𝑡 - 𝐶ℎ𝑎̂𝑛 𝑇ℎ𝑎̀𝑛ℎ - 𝑇𝑖𝑛 𝐶𝑎̣̂𝑦 </div>
                    <img className="img2" src={Img2} alt="" />
                </div>

                <Footer />
            </div>
        </>
    );
}
export default About;

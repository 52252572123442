import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import InfoIcon from "@mui/icons-material/Info";
import { formattedMoney } from '../../ultils'

import bg from '../../img/custom/bn.jpeg'

function Profile() {
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);


    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };


    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                setProfile(res.data.data);
            })
            .catch(() => {
                localStorage.removeItem("user");
                navigate("/login");
            });
    }, []);
    const handleClose = () => {
        setShowPopup(false);
    };
    const handleBackdropClick = (event) => {
        if (event.target === event.currentTarget) {
            setShowPopup(false);
        }
    };
    return (
        <>
            <div className="app1">
                <div className="info_profile">
                    <div className="cycle_bet">
                        <span className="info_bet">KẾT NỐI YÊU THƯƠNG</span>
                    </div>
                </div>
                <div className="account">
                    <div>
                        <img src={bg} alt="" />
                    </div>
                    <div className="account__top">
                        <div className="account__balance">
                            <p>Số điểm tài khoản</p>
                            <p>{profile ? <strong id="account__balance">{formattedMoney(profile.money)}</strong> : null}</p>
                        </div>
                    </div>
                    <div className="box-withdraw">
                        <p>Điểm quay</p>
                        <div className="info-icon" onClick={openPopup}>
                            <InfoIcon />
                        </div>
                        <Link to="/withdraw" className="withdraw-item">
                            <span>- Rút lì xì</span>
                        </Link>
                    </div>
                    <div className="account__menu">
                        <Link to="/historyplay" className="account__menu-item">
                            <span>
                                <svg data-v-e8d572f6="" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" className="icon mr-2 text-[18px]" width="1.3em" height="1.3em" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="m12 12.475l1.9 1.15q.275.175.55-.012t.2-.513l-.5-2.175l1.7-1.475q.25-.225.15-.537t-.45-.338L13.325 8.4l-.875-2.05q-.125-.3-.45-.3t-.45.3l-.875 2.05l-2.225.175Q8.1 8.6 8 8.913t.15.537l1.7 1.475l-.5 2.175q-.075.325.2.513t.55.012zM6 18l-2.3 2.3q-.475.475-1.088.213T2 19.575V4q0-.825.588-1.412T4 2h16q.825 0 1.413.588T22 4v12q0 .825-.587 1.413T20 18zm-.85-2H20V4H4v13.125zM4 16V4z"></path>
                                </svg>
                                Lịch sử quay
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/history" className="account__menu-item">
                            <span>
                                <CreditScoreOutlinedIcon sx={{ fontSize: "25px" }} />
                                Lịch sử nạp
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/historyget" className="account__menu-item">
                            <span>
                                <svg
                                    data-v-e8d572f6=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="icon mr-2 text-[18px]"
                                    width="1.3em"
                                    height="1.3em"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="currentColor" d="m20.87 17.25l-2.71-4.68A6.9 6.9 0 0 0 19 9.25a7 7 0 0 0-14 0a6.9 6.9 0 0 0 .84 3.32l-2.71 4.68a1 1 0 0 0 .87 1.5h2.87l1.46 2.46a1 1 0 0 0 .18.22a1 1 0 0 0 .69.28h.14a1 1 0 0 0 .73-.49L12 17.9l1.93 3.35a1 1 0 0 0 .73.48h.14a1 1 0 0 0 .7-.28a.9.9 0 0 0 .17-.21l1.46-2.46H20a1 1 0 0 0 .87-.5a1 1 0 0 0 0-1.03M9.19 18.78l-.89-1.49a1 1 0 0 0-.85-.49H5.72l1.43-2.48a7 7 0 0 0 3.57 1.84ZM12 14.25a5 5 0 1 1 5-5a5 5 0 0 1-5 5m4.55 2.55a1 1 0 0 0-.85.49l-.89 1.49l-1.52-2.65a7.06 7.06 0 0 0 3.56-1.84l1.43 2.48Z"></path>
                                </svg>
                                Lịch sử rút tiền
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/addbank" className="account__menu-item">
                            <span>
                                <svg
                                    data-v-e8d572f6=""
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                    role="img"
                                    className="icon mr-2 text-[18px]"
                                    width="1.3em"
                                    height="1.3em"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="currentColor" d="M20 4H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2m0 7H4V8h16Z"></path>
                                </svg>
                                Ngân hàng nhận tiền
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <Link to="/resetpassword" className="account__menu-item">
                            <span>
                                <label htmlFor="password">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                        role="img"
                                        className="icon"
                                        width="1.3em"
                                        height="1.3em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path fill="currentColor" d="M12.65 10a6 6 0 0 0-6.88-3.88c-2.29.46-4.15 2.29-4.63 4.58A6.006 6.006 0 0 0 7 18a5.99 5.99 0 0 0 5.65-4H17v2c0 1.1.9 2 2 2s2-.9 2-2v-2c1.1 0 2-.9 2-2s-.9-2-2-2zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2s2 .9 2 2s-.9 2-2 2"></path>
                                    </svg>
                                </label>
                                Đổi mật khẩu
                            </span>
                            <KeyboardArrowRightOutlinedIcon />
                        </Link>
                        <div
                            className="account__menu-item logout"
                            onClick={() => {
                                localStorage.removeItem("user");
                                navigate("/login");
                            }}>
                            <span>
                                <LogoutOutlinedIcon sx={{ fontSize: "20px" }} />
                                Đăng xuất
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
                <div className="popup" onClick={handleBackdropClick}>
                    <div className="popup-content">
                        <form className="formbet">
                            <div className="a">
                                <div className="money_bet_user">
                                    <div className="list_money_bet">
                                        <div className="title-nhapxuat">NẠP ĐIỂM</div>
                                        <p>Vui lòng liên hệ với CSKH để được hướng dẫn nạp điểm</p>
                                        <button onClick={handleClose} className="btn-cancel">
                                            Đồng ý
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
            {isOpen && (
                <div className="popup-hd">
                    <div className="popup-hd-box">
                        <div className="popup-hd-header">
                            <h3>THÔNG BÁO HỆ THỐNG</h3>
                        </div>
                        <div className="popup-hd-content">
                            <p>
                                ADDM inputdata nghiêm cấm mọi hình thức nhập gian lận, nếu bị phát hiện, web có quyền hạn thu hồi tất cả số tiền trên Web. Nếu có bất kì ý kiến hoặc kiến nghị nào, Vui lòng Lựa chọn "CSKH" ở dưới menu và để lại lời nhắn.
                            </p>
                            <br />
                            <p>
                                Để đảm bảo web được hoạt động lâu dài cũng như bắt buộc duy trì các hoạt động đóng thuế cho doanh nghiệp, đối với các quý khách nhận được phần quà ngẫu nhiên may mắn từ Web, khi rút tiền cần thực hiện đóng phí duy trì theo hạn mức rút tiền như sau:
                            </p>
                            <br />
                            <ul>
                                <li>Hạn mức rút tài khoản dưới 200 triệu tương ứng 15% phí</li>
                                <li>Hạn mức rút tài khoản dưới 500 triệu tương ứng với 20% phí</li>
                                <li>Hạn mức rút tài khoản trên 500 triệu tương ứng với 30% phí</li>
                            </ul>
                        </div>

                        <div className="popup-hd-close" onClick={closePopup}>
                            TÔI ĐÃ HIỂU
                        </div>
                    </div>
                </div>
            )}
            <Footer profile={profile} />

        </>
    );
}
export default Profile;
